<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" ref="DataForm">
      <el-form-item prop="keyword">
        <el-input
          v-model="dataForm.keyword"
          placeholder="输入关键字搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="did">
        <el-select
          v-model="dataForm.did"
          clearable
          placeholder="按部门筛选"
          @visible-change="departmentSelect"
          name="departmen"
        >
          <el-option
            v-for="item in departmentOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()"
          >发起风险评估</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        header-align="center"
        align="center"
        label="序号"
        type="index"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="fqrName"
        header-align="center"
        align="center"
        label="发起人"
      >
      </el-table-column>
      <el-table-column
        prop="fqDate"
        header-align="center"
        align="center"
        label="发起日期"
      >
      </el-table-column>
      <el-table-column
        prop="departmentName"
        header-align="center"
        align="center"
        label="部门"
      >
      </el-table-column>
      <el-table-column
        prop="liablePerson"
        header-align="center"
        align="center"
        label="部门责任人"
      >
      </el-table-column>
      <el-table-column
        prop="number"
        header-align="center"
        align="center"
        label="风险编号"
      >
      </el-table-column>
      <el-table-column
        prop="dangerType"
        header-align="center"
        align="center"
        label="风险的类别"
      >
      </el-table-column>
      <!-- <el-table-column
        prop="dangerDescribe"
        header-align="center"
        align="center"
        label="风险描述"
      >
      </el-table-column> -->
      <el-table-column
        prop="evaluateLevel"
        header-align="center"
        align="center"
        label="评价级别"
      >
      </el-table-column>
      <!-- <el-table-column
        prop="dangerLevel"
        header-align="center"
        align="center"
        label="风险分级"
      >
      </el-table-column> -->
      <el-table-column
        prop="controlType"
        header-align="center"
        align="center"
        label="管控措施类别"
      >
      </el-table-column>
      <el-table-column
        prop="controlLevel"
        header-align="center"
        align="center"
        label="管控层级"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0"
            >已发起风险评估，等待信息员填写</span
          >
          <span v-if="scope.row.status == 1"
            >信息员已上传填写结果，等待部门负责人审批</span
          >
          <span v-if="scope.row.status == 2"
            >部门负责人已审批，为一般风险，等待质安部管理员审批</span
          >
          <span v-if="scope.row.status == 3"
            >质安部管理员已审批，结果为合格</span
          >
          <span v-if="scope.row.status == 4"
            >质安部管理员已审批，结果为不合格，重新填写</span
          >
          <span v-if="scope.row.status == 5"
            >部门负责人已审批，为高风险，等待质安部负责人审批</span
          >
          <span v-if="scope.row.status == 6"
            >质安部负责人已审批，结果为合格</span
          >
          <span v-if="scope.row.status == 7"
            >质安部负责人已审批，结果为不合格，重新填写</span
          >
          <span v-if="scope.row.status == 8">质安部管理员已归档保存</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="290"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id, 1)"
            >查看</el-button
          >
          <el-button
            v-if="
              scope.row.status == 0 ||
              scope.row.status == 4 ||
              scope.row.status == 7
            "
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id, 0)"
            >填写</el-button
          >
          <el-button
            v-if="scope.row.status == 0"
            type="primary"
            plain
            size="mini"
            @click="addOrUpdateHandle(scope.row.id, 0, true)"
            >修改</el-button
          >

          <el-button
            v-if="scope.row.status == 1"
            type="primary"
            size="mini"
            @click="openApprovalBumen(scope.row.id)"
            >审核批准</el-button
          >
          <el-button
            v-if="scope.row.status == 2"
            type="primary"
            size="mini"
            @click="openApprovalZhianGuanli(scope.row.id)"
            >审核批准</el-button
          >
          <el-button
            v-if="scope.row.status == 3 || scope.row.status == 6"
            type="primary"
            size="mini"
            @click="archive(scope.row.id)"
            >归档保存</el-button
          >
          <el-button
            v-if="scope.row.status == 5"
            type="primary"
            size="mini"
            @click="openApprovalZhianFuzeren(scope.row.id)"
            >审核批准</el-button
          >
          <el-button
            type="danger"
            size="mini"
            plain
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>

    <el-dialog
      title="请选择风险分级"
      :visible.sync="approvalBumenDialogVisible"
      width="30%"
    >
      <el-select
        style="width: 100%"
        v-model="approvalBumenDataForm.status"
        placeholder="请选择风险分级"
      >
        <el-option
          v-for="item in approvalBumenOption"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="approvalBumenDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="approvalBumen">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="请选择审核结果"
      :visible.sync="approvalZhianGUanliDialogVisible"
      width="30%"
    >
      <el-select
        style="width: 100%"
        v-model="approvalZhianGUanliDataForm.status"
        placeholder="请选择审核结果"
      >
        <el-option
          v-for="item in approvalZhianGUanliOption"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="approvalZhianGUanliDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="approvalZhianGUanli">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="请选择审核结果"
      :visible.sync="approvalZhianFuzerenDialogVisible"
      width="30%"
    >
      <el-select
        style="width: 100%"
        v-model="approvalZhianFuzerenDataForm.status"
        placeholder="请选择审核结果"
      >
        <el-option
          v-for="item in approvalZhianFuzerenOption"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="approvalZhianFuzerenDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="approvalZhianFuzeren"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddOrUpdate from "./danger-add-or-update";

export default {
  data() {
    return {
      dataForm: {
        keyword: "",
        did: "",
      },

      approvalZhianFuzerenDialogVisible: false,
      approvalZhianFuzerenDataForm: {
        id: 0,
        status: 6,
      },
      approvalZhianFuzerenOption: [
        {
          value: 6,
          label: "合格",
        },
        {
          value: 7,
          label: "不合格",
        },
      ],
      approvalZhianGUanliDialogVisible: false,
      approvalZhianGUanliDataForm: {
        id: 0,
        status: 3,
      },
      approvalZhianGUanliOption: [
        {
          value: 3,
          label: "合格",
        },
        {
          value: 4,
          label: "不合格",
        },
      ],
      approvalBumenDialogVisible: false,
      approvalBumenDataForm: {
        id: 0,
        status: 2,
      },
      approvalBumenOption: [
        {
          value: 2,
          label: "一般风险",
        },
        {
          value: 5,
          label: "高风险",
        },
      ],

      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,

      departmentOptions: [], //部门列表选项
    };
  },
  components: {
    AddOrUpdate,
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 部门选择(打开下拉框)
    departmentSelect(e) {
      if (e) {
        this.$http.department.list({ limit: 99999, page: 1 }).then((res) => {
          this.departmentOptions = res?.data?.list;
        });
      }
    },

    // 打开治安部负责人审核批准
    openApprovalZhianFuzeren(id) {
      this.approvalZhianFuzerenDialogVisible = true;
      this.approvalZhianFuzerenDataForm.id = id;
    },
    // 质安部负责人审核批准
    approvalZhianFuzeren() {
      this.$http.danger
        .approvalZhianFuzeren(this.approvalZhianFuzerenDataForm)
        .then((res) => {
          if (res && res.code == 0) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
          this.getDataList();
          this.approvalZhianFuzerenDialogVisible = false;
        });
    },
    archive(id) {
      this.$http.danger.archive({ id: id }).then((res) => {
        if (res && res.code == 0) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.getDataList();
        this.approvalZhianGUanliDialogVisible = false;
      });
    },
    // 打开治安部管理员审核批准
    openApprovalZhianGuanli(id) {
      this.approvalZhianGUanliDialogVisible = true;
      this.approvalZhianGUanliDataForm.id = id;
    },
    // 质安部管理员审核批准
    approvalZhianGUanli() {
      this.$http.danger
        .approvalZhianGuanli(this.approvalZhianGUanliDataForm)
        .then((res) => {
          if (res && res.code == 0) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
          this.getDataList();
          this.approvalZhianGUanliDialogVisible = false;
        });
    },
    // 部门负责人审核批准
    approvalBumen() {
      this.$http.danger
        .approvalBumen(this.approvalBumenDataForm)
        .then((res) => {
          if (res && res.code == 0) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
          this.getDataList();
          this.approvalBumenDialogVisible = false;
        });
    },
    // 打开部门负责人审核批准
    openApprovalBumen(id) {
      this.approvalBumenDialogVisible = true;
      this.approvalBumenDataForm.id = id;
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      this.$http.danger.list(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改 flag：1查看  0填写
    addOrUpdateHandle(id, flag, isUpdate) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, flag, isUpdate);
      });
    },
    // 删除
    deleteHandle(id) {
      // 全局mixin混入事件；val输入的备注(then：确认按钮后的操作；catch：取消后的操作)
      this.remarksDelete()
        .then((val) => {
          this.$http.danger
            .delete({
              id,
              remarks: val,
            })
            .then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: data.msg,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.getDataList();
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>
